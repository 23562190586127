.navbar {
    background-color: #1e2124;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
  }
  
  .nav-menu {
    background-color: #1e2124;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
    background-color: #1e2124;

  }
  
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    background-color: #1e2124;

  }
  
  
  
  .nav-text a:hover {
    background-color: #1a83ff;
    background-color: #1e2124;
  }
  
  .nav-menu-items {
    width: 100%;
    background-color: #1e2124;

  }
  
  .navbar-toggle {
    background-color: #1e2124;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
    background-color: #1e2124;
  }

  .icons {
    background-color: #1e2124;
    color: #fff
  }

  

* {
    margin: 0;
    left: 0;
    top: 0;
    padding: 0;
    background-color: #23272a;
}

.signin-box:hover {
    cursor: pointer;
}
.account-box:hover {
    cursor: pointer
}

.home-box:hover {
    cursor: pointer;
}


