* {
    margin: 0;
    left: 0;
    top: 0;
    padding: 0;
    background-color: #23272a;
}

.signin-box:hover {
    cursor: pointer;
}
.account-box:hover {
    cursor: pointer
}

.home-box:hover {
    cursor: pointer;
}

